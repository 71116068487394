import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { getDateTime, getResumeDate } from '@genoa/utils'

import { useAgreements, useAutopilotEligibility, useInterestFeeInfoModal, useIsEmbed } from '../../../../../hooks'
import { useAcceptOffer, useGetOffer } from '../../../../../hooks/flex2/risk'
import { useAccount } from '../../../../../hooks/use-account'
import { OnboardingStatus, setOnboardingStatus, useAnalytics, useIterable } from '../../../../../providers'
import { createSignUpCompletedEvent } from '../../../../../providers/iterable/user-events'
import { App } from '../../../../../routing/constants'
import * as Routes from '../../../../../routing/constants'
import { FullScreenSpinnerLoading, Gap } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { useInstallmentPaymentItems } from '../payment-schedule'
import { SLCAgreementsModal } from './SLCAgreementsModal'
import { SLCConfirmSchedule } from './SLCConfirmSchedule'
import { useAcceptOfferModal } from './use-accept-offer-modal'
import { useCardDetails } from './use-card-details'

export interface SLCConfirmScheduleContainerProps {
  readonly analyticsScreenName: Analytics.Screens
  readonly onRegisterCard: () => void
  readonly onChangeAutopay: () => void
  readonly onNext: () => void
}

export const SLCConfirmScheduleContainer = (props: SLCConfirmScheduleContainerProps) => {
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const iterable = useIterable()
  const cardDetails = useCardDetails({ capitalizeCardNetwork: true })
  const acceptOfferModal = useAcceptOfferModal()
  const { showInterestFeeInfoModal } = useInterestFeeInfoModal()
  const payments = useInstallmentPaymentItems({ showInterestFeeInfoModal })
  const { billerConnection } = useAccount()
  const { getOffer } = useGetOffer()
  const today = getDateTime()
  const resumeDate = getResumeDate(today)
  const {
    isUserAutopayEnabled,
    refetchEligibility: refetchAutopilotEligibility,
    isLoadingEligibility: loadingAutopilot,
  } = useAutopilotEligibility()
  const isEmbed = useIsEmbed()

  const [showAgreementsModal, setShowAgreementsModal] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)

  const handleSuccess = () => {
    analytics.logEvent(Analytics.Events.ACCEPT_OFFER_SUCCESS)
    iterable.addEvent(createSignUpCompletedEvent())
    setOnboardingStatus(OnboardingStatus.Active)
    analytics.setUserProperty(
      Analytics.UserProperties.ONBOARDING_COMPLETION_TIMESTAMP,
      new Date().toISOString().slice(0, 19),
      true
    )
    if (isEmbed && !isUserAutopayEnabled) {
      return navigate(Routes.Embed.CONGRATS)
    }
    return props.onNext()
  }

  const { loading: loadingAgreements, agreements } = useAgreements({ onError: () => navigate(App.TRY_AGAIN) })

  const { loading, acceptOffer } = useAcceptOffer({
    onSuccess: handleSuccess,
    onRateLimitExceeded: acceptOfferModal.showRateLimitExceededModal,
    onDataError: acceptOfferModal.showDataErrorModal,
    onGenericError: acceptOfferModal.showGenericErrorModal,
  })

  useEffect(() => {
    const handleGetOffer = async () => {
      if (billerConnection?.biller_account_public_id) {
        await getOffer(billerConnection?.biller_account_public_id)
      }
    }
    handleGetOffer()
  }, [billerConnection?.biller_account_public_id])

  useEffect(() => {
    refetchAutopilotEligibility()
  }, [])

  const onContinue = () => {
    if (!cardDetails) {
      return
    }

    setShowAgreementsModal(true)
  }

  if (payments.length === 0 || loadingAgreements || loadingAutopilot) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_300" />
      <SLCConfirmSchedule
        payments={payments}
        cardDetails={cardDetails}
        continueDisabled={cardDetails === undefined}
        paymentMonth={resumeDate.monthLong!}
        onAddCard={props.onRegisterCard}
        onChangeCard={props.onRegisterCard}
        onChangeAutopay={props.onChangeAutopay}
        onContinue={onContinue}
      />
      <SLCAgreementsModal
        visible={showAgreementsModal}
        agreements={agreements}
        accepted={termsAccepted}
        loading={loading}
        onClose={() => setShowAgreementsModal(false)}
        onToggleAccept={setTermsAccepted}
        onAccept={acceptOffer}
      />
    </BasePageLayout>
  )
}
