import { Analytics } from '@genoa/analytics'
import cleanLogData from '@genoa/utils/lib/clean-log-data'
import sha256 from 'crypto-js/sha256'

import { allowsEnhancedTracking } from '../amplitude'
import { getAdClickIds } from './use-ad-click-identifiers'

/**
 * sanitizes event data by stripping away PII data like email with "clean-log-data.ts" utility wrapper
 * in addition, if the event allows for enhanced tracking and the original event data contained the "email" attribute,
 * then adds back the "email" attribute as SHA-256 hashed version of the original
 */
export const sanitizeEventData = (event: Analytics.Events, enhancedTrackingEnabled: boolean, data?: any) => {
  if (data === undefined) {
    // nothing to sanitize!
    return undefined
  }

  // round 1: use clean-log-data.ts
  const cleanedLogData = cleanLogData(data)
  if (!allowsEnhancedTracking(event) || !enhancedTrackingEnabled) {
    // event doesn't allow for enhanced tracking or MarTech Enhanced Tracking is turned off
    return cleanedLogData
  }

  // event requiring enhanced tracking found!
  // round 2: enrich log data with advertising click identifier(s)
  const enhancedLogData = {
    ...cleanedLogData,
    ...getAdClickIds(),
  }

  // round 3: add email back if one was present in the original data
  const email = data.email
  if (email === undefined) {
    // no email to add to data
    return enhancedLogData
  }

  // sha-256 hashed email is added and returned
  return {
    ...enhancedLogData,
    email: sha256(email).toString(),
  }
}
