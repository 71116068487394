import { extendTheme } from '@chakra-ui/react'

import buttons from './buttons'
import checkbox from './checkbox'
import palette from './color-palette'
import global from './global'
import inputs from './inputs'
import { media } from './media'

const flexTheme = {
  styles: {
    global,
  },

  media,

  components: {
    Button: buttons,
    Input: inputs,
    Checkbox: checkbox,
    AppStore: {
      height: '65px',
      width: '65px',
    },
  },

  fonts: {
    heading: 'RebrandDis',
    body: 'Lexend',
  },

  fontSizes: {
    xxs: '12px',
    xs: '14px',
    sm: '16px',
    md: '16px',
    lg: '24px',
    xl: '36px',
    button: '20px',
  },

  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },

  lineHeights: {
    xxs: '16.8px',
    xs: '19.6px',
    sm: '27px',
    md: '30px',
    lg: '40px',
  },

  fixedSizes: {
    spacing_0: '0px',
    spacing_6_25: '1px',
    spacing_12_5: '2px',
    spacing_25: '4px',
    spacing_37_5: '6px',
    spacing_50: '8px',
    spacing_62_5: '10px',
    spacing_75: '12px',
    spacing_100: '16px',
    spacing_125: '20px',
    spacing_150: '24px',
    spacing_175: '28px',
    spacing_200: '32px',
    spacing_225: '36px',
    spacing_250: '40px',
    spacing_300: '48px',
    spacing_350: '56px',
    spacing_400: '64px',
    spacing_500: '80px',
    spacing_600: '96px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '32px',
    xl: '64px',
  },

  radii: {
    sm: '2px',
    md: '4px',
    lg: '8px',
    xl: '12px',
    modal: '16px',
    rounded: '999px',
  },

  modalContents: {
    minWidth: '640px',
    maxWidth: '640px',
    embedWidth: '540px',
  },

  colors: {
    ...palette,

    text: {
      100: palette.grey,
      900: palette.black,
      quiet: palette.grey,
    },

    error: palette.fireEngine,
    lineDivider: palette.cloud,
    hoveredContainer: palette.alabasterGrey,
    background: palette.white,
    foreground: palette.black,
    transparentCardBorderColor: palette.aluminiumGrey,
  },
} as const

export type FlexTheme = typeof flexTheme

export type Spacing = keyof FlexTheme['fixedSizes']

const theme = extendTheme(flexTheme) as FlexTheme

export default theme
