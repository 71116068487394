import { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import {
  ActionLogType,
  isBillerFlexAnywhereLongTailPortal,
  isBillerPropertyDetails,
  isGenericPortal,
} from '@genoa/domain'
import {
  BillerAccountStatus,
  GetIdentityMatchStatusResponse,
  IdentityMatchStatus,
  useCreateCustomerIdentity,
  useGetIdentityMatchStatus,
  useUpdateCustomerIdentity,
} from '@genoa/middle-end'

import { useAuthState } from '../../contexts'
import { resolveAddress, useAnalytics, useIterable, useLogger } from '../../providers'
import { createBillerConnectionSuccessEvent } from '../../providers/iterable/user-events'
import { useEmbed } from '../embed/use-embed'
import { usePollingRequest } from '../flex2'
import { useHandleRegisterActionLog } from '../flex2/onboarding-status'
import { useFlexAnywhereUser } from '../flexAnywhere'
import { useAccount } from '../use-account'
import {
  ConnectBillerEventData,
  ConnectBillerHandlerParams,
  ConnectBillerType,
  ConnectingProperty,
  validPostMatchStatus,
} from './types'

export interface UseHandleConnectBillerOptions {
  readonly componentName: string
  readonly connectionType: ConnectBillerType
  readonly onConnected: () => void
  readonly onError: () => void
  readonly setConnecting: (value: boolean) => void
}

export const useHandleConnectBiller = ({
  componentName,
  onConnected,
  onError,
  setConnecting,
  connectionType,
}: UseHandleConnectBillerOptions) => {
  const { user } = useAuthState()
  const analytics = useAnalytics()
  const iterable = useIterable()
  const { refetch: refetchAccount, billerConnection } = useAccount()
  const loggerV2 = useLogger(`use-handle-connect-biller - ${componentName}`)
  const embed = useEmbed()

  const [, updateCustomerIdentity] = useUpdateCustomerIdentity()
  const [, createCustomerIdentity] = useCreateCustomerIdentity()
  const [, getIdentityMatchStatus] = useGetIdentityMatchStatus()

  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()
  const isOutOfNetworkUser = useFlexAnywhereUser()

  const getBillerLookup = (connectingProperty: ConnectingProperty) => {
    if (isGenericPortal(connectingProperty)) {
      // shouldn't happen but type-check
      if (connectingProperty.generic_portal_identity_biller_id === null) {
        throw new Error('Unable to find biller_id for property.')
      }

      return { biller_id: connectingProperty.generic_portal_identity_biller_id?.toFixed() }
    }

    if (isBillerPropertyDetails(connectingProperty)) {
      return { biller_id: connectingProperty.identity_biller_id.toFixed() }
    }

    if (isBillerFlexAnywhereLongTailPortal(connectingProperty)) {
      return { biller_public_id: connectingProperty.public_id, biller_id: connectingProperty.biller_id?.toFixed() }
    }

    return { biller_public_id: connectingProperty.public_id }
  }

  const { startPolling: startIdentityMatchPolling, loading: identityMatchLoading } =
    usePollingRequest<GetIdentityMatchStatusResponse>(
      useCallback(async () => {
        if (user?.uid) {
          try {
            const res = await getIdentityMatchStatus({ customerPublicId: user?.uid })
            if (res?.data?.match_status !== IdentityMatchStatus.MATCHING) {
              return res.data
            }
          } catch (error: any) {
            loggerV2.error('Error polling request callback', error?.message)
          }
        }
        return false
      }, [user?.uid]),
      120000,
      5000
    )

  const handleErrorLogging = (eventData: ConnectBillerEventData, errorReason: string, billingResponse?: string) => {
    analytics.logEvent(Analytics.Events.BILLER_CONNECTION_FAIL, eventData)
    if (billingResponse !== '' && billingResponse !== undefined) {
      loggerV2.warn(`Biller connection failure due to ${billingResponse}`)
    } else {
      loggerV2.error('Biller connection error', errorReason, eventData)
    }
  }

  const handleBadCustomerResponse = (eventData: ConnectBillerEventData, status: number, billerResponse?: string) => {
    handleErrorLogging(
      eventData,
      `Error trying to ${eventData.operation} customer with biller type ${eventData.biller_type} status: ${status}`,
      billerResponse
    )
    return onError()
  }

  const handleBadBillerStatus = (
    eventData: ConnectBillerEventData,
    accountStatus?: string,
    billerResponse?: string
  ) => {
    handleErrorLogging(
      {
        ...eventData,
        status: accountStatus,
      },
      'Biller linked to account is not valid',
      billerResponse
    )
    return onError()
  }

  const handleSuccessfulBillerResponse = (eventData: ConnectBillerEventData, connectedProperty: ConnectingProperty) => {
    loggerV2.info('Biller connection success', undefined, eventData)
    analytics.logEvent(Analytics.Events.BILLER_CONNECTION_SUCCESS, eventData)
    iterable.addEvent(createBillerConnectionSuccessEvent(connectedProperty))
    return onConnected()
  }

  const handleIdentityMatching = async (
    eventData: ConnectBillerEventData,
    connectingProperty: ConnectingProperty,
    successHandler?: (billerAccountPublicId?: string) => Promise<any>
  ) => {
    if (!user?.uid) {
      return onError()
    }

    try {
      analytics.logEvent(Analytics.Events.BILLER_CONNECTION_MATCHING, eventData)
      const pollingResult = await startIdentityMatchPolling()
      await refetchAccount()

      if (
        pollingResult &&
        validPostMatchStatus.includes(pollingResult.account_status) &&
        pollingResult.match_status === IdentityMatchStatus.MATCHED
      ) {
        await successHandler?.(pollingResult.biller_account_public_id)
        return handleSuccessfulBillerResponse(eventData, connectingProperty)
      }

      handleErrorLogging(
        eventData,
        `Error trying to ${eventData.operation} customer with biller type ${connectionType} status: ${pollingResult?.account_status}, matching_status: ${pollingResult?.match_status} info: ${pollingResult?.matched_response}`,
        pollingResult?.matched_response
      )
    } catch (error: any) {
      handleErrorLogging(eventData, error)
    }

    return onError()
  }

  const connectBillerHandler = useCallback(
    async ({
      connectionType,
      eventData,
      customerId,
      operation,
      credentials,
      userAccount,
      userInfo,
      unit,
      connectingProperty,
    }: ConnectBillerHandlerParams) => {
      const isUpdate = operation === 'update'
      const isPortalConnection = connectionType === 'portal'
      const actionLogType =
        operation === 'create' ? ActionLogType.CREATE_CUSTOMER_ACCOUNT : ActionLogType.UPDATE_CUSTOMER_ACCOUNT

      if (!(user && userAccount.firstName && userAccount.lastName && userAccount.email && userAccount.phone)) {
        loggerV2.error(`${operation} customer account error`, 'Missing user, name, email or phone')
        return onError()
      }

      const tryRegisterActionLog = async () => {
        return handleRegisterActionLog(actionLogType)
      }

      try {
        const resolvedAddress = resolveAddress(userInfo)
        const billerLookup = getBillerLookup(connectingProperty)

        const requestData = {
          customerPublicId: customerId,
          biller_lookup: billerLookup,
          customer: {
            customer_public_id: customerId,
            first_name: userAccount.firstName,
            last_name: userAccount.lastName,
            email: userAccount.email,
            phone: userAccount.phone,
            address_line1: resolvedAddress.address_line1,
            state: resolvedAddress.state,
            city: resolvedAddress.city,
            zip: resolvedAddress.zip,
            customer_type: 'normal',
          },
          portal_credentials: credentials,
          unit_nbr: unit?.unit || (isOutOfNetworkUser && userInfo?.unit) || undefined,
          ...(embed.isEmbed && { token_lookup: { client: embed.client, token: embed.token } }),
        }

        const handler = !isUpdate ? createCustomerIdentity : updateCustomerIdentity

        const response = await handler({
          ...requestData,
          target_account: {
            biller_account_public_id: billerConnection?.biller_account_public_id || '',
          },
        })

        const currentAccountStatus = response.data?.new_account?.status
        const activeAccountStatus = response.data?.active_account?.status
        const accountStatus = currentAccountStatus || activeAccountStatus
        const hasValidAccountStatus = accountStatus && Object.values(BillerAccountStatus).includes(accountStatus)

        const billerMatchResponse = response.data?.property_match?.matched_response
        const billerMatchStatus = response.data?.property_match?.matched_status

        if (!isPortalConnection) {
          await refetchAccount() // make sure store is up to date
          if (billerMatchStatus && billerMatchStatus !== IdentityMatchStatus.MATCHED) {
            handleErrorLogging(eventData, `Match status is invalid: ${billerMatchStatus}`)
            return onError()
          }
        }

        if (hasValidAccountStatus && (response?.status === 200 || response?.status === 201)) {
          if (isPortalConnection && billerMatchStatus && billerMatchStatus === IdentityMatchStatus.MATCHING) {
            return handleIdentityMatching(eventData, connectingProperty, async () => {
              return tryRegisterActionLog()
            })
          } else if (isPortalConnection) {
            await refetchAccount()
          }

          switch (accountStatus) {
            case BillerAccountStatus.PENDING:
            case BillerAccountStatus.ACTIVE:
              await tryRegisterActionLog()
              return handleSuccessfulBillerResponse(eventData, connectingProperty)
            default:
              return handleBadBillerStatus(eventData, accountStatus, billerMatchResponse)
          }
        }
        return handleBadCustomerResponse(eventData, response?.status, billerMatchResponse)
      } catch (error: any) {
        await refetchAccount()
        handleErrorLogging(eventData, `Unhandled error: ${error?.message}`)
      } finally {
        setConnecting(false)
      }
    },
    [user, onError]
  )

  return { connectBillerHandler, connecting: identityMatchLoading || loadingRegisterActionLog }
}
