export const App = {
  AUTH: '/auth',
  LANDING: '/landing',
  ONBOARDING: '/onboarding',
  ACCEPT_TERMS: '/terms-confirmation',
  TRY_AGAIN: '/try-again',
  VERIFY_EMAIL: '/verify-email',
  APP_REDIRECT: '/app-redirect',
  HEALTHCHECK: '/healthcheck',
  EMBED: '/embed',
}

export const Auth = {
  PHONE_CREATE_ACCOUNT: `${App.AUTH}/create-account`,
  PHONE: `${App.AUTH}/phone`,
  PIN: `${App.AUTH}/pin`,
}

export const Onboarding = {
  CREATE_ACCOUNT: `${App.ONBOARDING}/account`,
  CONFIRM_ACCOUNT: `${App.ONBOARDING}/confirm-account`,
  CREDIT_BUILDER_VALUE_PROP: `${App.ONBOARDING}/why-credit-builder`,
  RESUME_ONBOARDING: `${App.ONBOARDING}/resume-onboarding`,
  ADDRESS: `${App.ONBOARDING}/address`,
  PROPERTY: `${App.ONBOARDING}/property`,
  PORTAL_ADDRESS_ENTRY: `${App.ONBOARDING}/portal-address`,
  RENT_PORTAL_DETAILS: `${App.ONBOARDING}/portal-details`,
  RENT_PORTAL_SELECTION: `${App.ONBOARDING}/portal-selection`,
  RENT_PORTAL_SIGNIN: `${App.ONBOARDING}/portal-signin`,
  FLEX_ANYWHERE_WHERE_YOU_PAY: `${App.ONBOARDING}/flex-anywhere-where-you-pay`,
  FLEX_ANYWHERE_HOW_YOU_PAY: `${App.ONBOARDING}/flex-anywhere-how-you-pay`,
  FLEX_ANYWHERE_WAITLIST: `${App.ONBOARDING}/flex-anywhere-waitlist`,
  FLEX_ANYWHERE_HOW_FLEX_WORKS: `${App.ONBOARDING}/flex-anywhere-how-flex-works`,
  DIRECT_INTEGRATION_UNIT: `${App.ONBOARDING}/direct-integration-unit`,
  DIRECT_INTEGRATION_CONFIRMATION: `${App.ONBOARDING}/direct-integration-confirm`,
  RENT_AMOUNT: `${App.ONBOARDING}/rent-amount`,
  CARD_STAGE_SELECTION: `${App.ONBOARDING}/card-stage-selection`,
  CARD_CONFIRM: `${App.ONBOARDING}/confirm-card`,
  CARD_CONNECT: `${App.ONBOARDING}/connect-card`,
  CARD_REGISTER: `${App.ONBOARDING}/register-card`,
  CARD_UPDATE_BILLING_ADDRESS: `${App.ONBOARDING}/update-card-billing-address`,
  SOFT_CREDIT_CHECK: `${App.ONBOARDING}/soft-credit-check`,
  SCHEDULE: `${App.ONBOARDING}/schedule`,
  SCHEDULE_CONFIRM_OFFER: `${App.ONBOARDING}/schedule_offer`,
  OFFER_DETAILS: `${App.ONBOARDING}/offer-details`,
  TERMS: `${App.ONBOARDING}/terms`,
  CONGRATS: `${App.ONBOARDING}/congrats`,
  CONTACT_SUPPORT: `${App.ONBOARDING}/contact-support`,
  NOT_APPROVED: `${App.ONBOARDING}/not-approved`,
  CUSTOMIZE_SCHEDULE: `${App.ONBOARDING}/customize-schedule`,
  CHOOSE_DATE: `${App.ONBOARDING}/choose-date`,
  AUTOPILOT: `${App.ONBOARDING}/autopay`,
  APPROVED: `${App.ONBOARDING}/approved`,
  HOW_FLEX_WORKS: `${App.ONBOARDING}/how-flex-works`,
  HOW_TO_PAY_FLEX_BACK: `${App.ONBOARDING}/how-to-pay-flex-back`,
  AUTOPAY_SETUP: `${App.ONBOARDING}/autopay-setup`,
  SLC_PRODUCT_SELECTION: `${App.ONBOARDING}/product-selection`,
  UPSELL_ELIGIBILITY: `${App.ONBOARDING}/product-eligibility`,
  CREDIT_BUILDER_UPSELL: `${App.ONBOARDING}/try-credit-builder`,
  CREDIT_BUILDER_UPSELL_NOT_APPROVED: `${App.ONBOARDING}/credit-builder-not-approved`,
}

export const Embed = {
  CONGRATS: `${App.EMBED}/congrats`,
  SIMPLIFIED_EPISODIC: `${App.EMBED}/simplified-episodic`,
  MANUAL_PAY_FINISH: `${App.EMBED}/manual-pay-finish`,
  ALREADY_FLEXING_YOUR_RENT: `${App.EMBED}/already-flexing-your-rent`,
  BUILD_CREDIT_IN_APP: `${App.EMBED}/build-credit-in-app`,
  NOT_RENT_DAY: `${App.EMBED}/not-rent-day`,
  RENT_SPLIT_PROGRESS: `${App.EMBED}/rent-split-progress`,
  SERVICE_ISSUE: `${App.EMBED}/service-issue`,
  SOMETHING_WENT_WRONG: `${App.EMBED}/something-went-wrong`,
}
