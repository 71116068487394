import { Analytics } from '@genoa/analytics'
import { BillerPropertyDetails, IntegrationType, SLCType } from '@genoa/domain'
import { BillerConnection, Customer } from '@genoa/state-management'

import { useIsEmbed } from '../../hooks'
import { useAnalytics } from '../analytics'

const resolveCustomerInfo = (customer?: Customer) => {
  if (customer === undefined) {
    return {}
  }

  const { email, public_id } = customer
  return {
    email,
    customerId: public_id,
  }
}

const resolvePropertyInfo = (property?: BillerPropertyDetails) => {
  if (property === undefined) {
    return {}
  }

  const { name, pmc_name, integration_type, biller_integration_type } = property
  return {
    propName: name,
    pmcName: pmc_name,
    integrationType: (() => {
      switch (integration_type) {
        case IntegrationType.YARDI:
          return `${IntegrationType[IntegrationType.DIRECT_INTEGRATION]}_${IntegrationType[IntegrationType.YARDI]}`
        case IntegrationType.REALPAGE:
          return `${IntegrationType[IntegrationType.DIRECT_INTEGRATION]}_${IntegrationType[IntegrationType.REALPAGE]}`
        case IntegrationType.DIRECT_INTEGRATION:
          if (biller_integration_type !== undefined && biller_integration_type !== null) {
            return `${IntegrationType[IntegrationType.DIRECT_INTEGRATION]}_${biller_integration_type}`
          }
          return IntegrationType[IntegrationType.DIRECT_INTEGRATION]
        case IntegrationType.FLEX_ANYWHERE:
        case IntegrationType.PORTAL:
          return IntegrationType[integration_type]
        default:
          return integration_type
      }
    })(),
  }
}

const resolveProductTypeInfo = (slcType?: SLCType) => {
  return {
    productType: (() => {
      if (slcType === undefined || slcType === null) {
        return 'RENT_SPLIT_LEGACY'
      } else if (slcType === SLCType.CLASSIC) {
        return 'SLC'
      }
      return SLCType[slcType]
    })(),
  }
}

export const useMarTechTracking = () => {
  const analytics = useAnalytics()
  const isEmbed = useIsEmbed()
  const sharedProperties = {
    embedFlow: isEmbed ? 'yardi' : 'none',
  }

  return {
    trackBillConnected: (customer?: Customer, property?: BillerPropertyDetails) => {
      analytics.logEvent(Analytics.Events.BILL_CONNECTED, {
        ...resolveCustomerInfo(customer),
        ...resolvePropertyInfo(property),
        ...sharedProperties,
      })
    },
    trackUserSubmittedCreditCheckInfo: (
      customer?: Customer,
      billerConnection?: BillerConnection,
      property?: BillerPropertyDetails,
      slcType?: SLCType,
      rentAmount?: string
    ) => {
      analytics.logEvent(Analytics.Events.UNDERWRITING_REQUESTED, {
        ...resolveCustomerInfo(customer),
        ...resolvePropertyInfo(property),
        ...resolveProductTypeInfo(slcType),
        ...sharedProperties,
        ...(rentAmount !== undefined && { rentAmount }),
      })
    },
    trackUserSignUpCompleted: (
      customer?: Customer,
      billerConnection?: BillerConnection,
      property?: BillerPropertyDetails,
      slcType?: SLCType,
      rentAmount?: string
    ) => {
      analytics.logEvent(Analytics.Events.SIGNUP_COMPLETED, {
        ...resolveCustomerInfo(customer),
        ...resolvePropertyInfo(property),
        ...resolveProductTypeInfo(slcType),
        ...sharedProperties,
        rentAmount,
      })
    },
    trackUserClickedContinueOnLoanApprovalModal: (
      customer?: Customer,
      billerConnection?: BillerConnection,
      property?: BillerPropertyDetails,
      slcType?: SLCType,
      rentAmount?: string
    ) => {
      analytics.logEvent(Analytics.Events.CUSTOMER_ACCEPTED_LOAN, {
        ...resolveCustomerInfo(customer),
        ...resolvePropertyInfo(property),
        ...resolveProductTypeInfo(slcType),
        ...sharedProperties,
        rentAmount,
      })
    },
  }
}
