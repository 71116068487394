import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'

import { useReduxSelector, useSecureLineOfCredit } from '../../../../../hooks'
import { useAccount } from '../../../../../hooks/use-account'
import { RootState } from '../../../../../modules'
import { useMarTechTracking } from '../../../../../providers'
import { SLCCongrats } from './SLCCongrats'

export const SLCCongratsContainer = () => {
  const { slcType } = useSecureLineOfCredit()
  const { customer, billerConnection } = useAccount()
  const property = useReduxSelector(({ propertyLinking }: RootState) => propertyLinking?.property)
  const rentAmount = useReduxSelector(({ onboarding }: RootState) => onboarding?.rentAmount?.amount)
  const marTech = useMarTechTracking()

  useEffect(() => {
    marTech.trackUserSignUpCompleted(customer, billerConnection, property, slcType, rentAmount)
  }, [])

  return <SLCCongrats analyticsScreenName={Analytics.Screens.ONBOARDING_CONGRATS} />
}
