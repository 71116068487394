import { Analytics } from '@genoa/analytics'

/**
 * Enhanced Tracking controls if a custom Amplitude event is forwarded to Google Tag Manager with the Amplitude Google
 * Tag Manager Destination Plugin
 */

const { Events: E } = Analytics
const ALL_ENHANCED_TRACKING_EVENTS: Set<Analytics.Events> = new Set([
  E.ACCOUNT_CREATED,
  E.PHONE_VERIFIED,
  E.BILL_CONNECTED,
  E.UNDERWRITING_REQUESTED,
  E.SIGNUP_COMPLETED,
  E.CUSTOMER_ACCEPTED_LOAN,
])

export const allowsEnhancedTracking = (evt: any) => ALL_ENHANCED_TRACKING_EVENTS.has(evt)
