import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { DATE_LENGTH, FlexLinks, isDOBValid, isValidITIN, isValidSSN } from '@genoa/domain'
import {
  OfferStates,
  OrchestrationEvaluateOfferResponse,
  OrchestrationOfferResponse,
  useEvaluateOffer,
} from '@genoa/middle-end'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import * as yup from 'yup'

import { useAuthState, useModal } from '../../../../contexts'
import { useAutopilotEligibility, useGeometricInterval, useReduxAction, useReduxSelector } from '../../../../hooks'
import { useSecureLineOfCredit, useTrackExposureSecureLineOfCredit } from '../../../../hooks/secure-line-of-credit'
import { useAccount } from '../../../../hooks/use-account'
import { RootState } from '../../../../modules'
import { setOfferAction } from '../../../../modules/flex2/offer'
import { setPricingOfferAction } from '../../../../modules/flex2/pricing-offer'
import { useOfferState } from '../../../../modules/flex2/use-state'
import { useAnalytics, useFlexLinks, useIterable, useLogger } from '../../../../providers'
import { useContent } from '../../../../providers/content'
import {
  createUnderwritingApprovedEvent,
  createUnderwritingDeniedEvent,
} from '../../../../providers/iterable/user-events'
import { useMarTechTracking } from '../../../../providers/martech'
import * as Routes from '../../../../routing/constants'
import { SmallText } from '../../../components'
import { DateOfBirth, ssn as SSNMask } from '../../../components/Input'
import { useIncomeCollectionExperiment } from './use-income-collection-experiment'
import { SoftCreditCheck, SoftCreditCheckState } from './V2SoftCreditCheck'
import { WaitingOffer } from './WaitingOffer'

const ssnPlaceholder = 'XXX - XX - XXXX'

const isValidResponseData = (data: any): data is OrchestrationOfferResponse => {
  return typeof data === 'object' && 'risk_offer' in data
}

const softCreditCheckSchema = yup.object({
  dob: yup.string().required('Date of Birth is required').trim(),
  ssn: yup.string().required('SSN is required').trim(),
  income: yup.string(),
})

export const V2SoftCreditCheckContainer = () => {
  const [loadingEvaluation, setLoadingEvaluation] = useState(false)
  const { uid } = useAuthState()
  const { customer, billerConnection } = useAccount()
  const logger = useLogger('V2SoftCreditCheckContainer')
  const setOfferState = useReduxAction(setOfferAction)
  const setPricingOfferState = useReduxAction(setPricingOfferAction)
  const isIncomeCollectionEnabled = useIncomeCollectionExperiment()
  const modal = useModal()
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const marTech = useMarTechTracking()
  const iterable = useIterable()
  const flexLinks = useFlexLinks()
  const theme = useTheme()
  const { refetchEligibility, isUserEligibleForAutopilot } = useAutopilotEligibility()
  const { refetchSLC, isEnabledForSLC, isFlagEnabledForSLC, slcType } = useSecureLineOfCredit()
  const { trackSLCExposure } = useTrackExposureSecureLineOfCredit()
  const property = useReduxSelector(({ propertyLinking }: RootState) => propertyLinking.property)
  const rentAmount = useReduxSelector(({ onboarding }: RootState) => onboarding?.rentAmount?.amount)
  const {
    content: { WHY_WE_COLLECT_PERSONAL_INFO_MODAL },
  } = useContent()

  const {
    clearErrors,
    control,
    formState: { errors: formErrors },
    getValues,
    handleSubmit,
    setError,
    setValue,
  } = useForm<SoftCreditCheckState>({
    resolver: yupResolver(softCreditCheckSchema),
    mode: 'onChange',
  })

  const { start, stop } = useGeometricInterval(
    useCallback((interval, elapsed) => {
      analytics.logEvent(Analytics.Events.ONBOARDING_WAITING_OFFER, { interval, elapsed })
    }, []),
    15000
  )

  const redirectToContactSupport = () => {
    navigate(Routes.Onboarding.CONTACT_SUPPORT, { replace: true })
  }

  const offerState = useOfferState()

  const [{ response }, evaluateOffer] = useEvaluateOffer()

  const getErrorModalContent = (status: number, data: OrchestrationEvaluateOfferResponse) => {
    if (status === 422 && !isValidResponseData(data) && data?.message) {
      return {
        children: <SmallText>{data.message}</SmallText>,
        title: 'Something went wrong',
      }
    } else if (status === 400) {
      return {
        children: (
          <SmallText>
            Your date of birth and social security number must match the information you entered when you created your
            account.
          </SmallText>
        ),
        title: 'Information doesn’t match our records',
      }
    }
    return {
      children: (
        <SmallText>
          We were unable to process your application. Try again or{' '}
          <SmallText
            onClick={() => flexLinks.open(FlexLinks.supportTicketWeb)}
            color={theme.colors.brand[200]}
            fontWeight="bold"
          >
            reach out to our support team
          </SmallText>{' '}
          <SmallText>for assistance.</SmallText>
        </SmallText>
      ),
      title: 'Something went wrong',
    }
  }

  const handleShowErrorMessage = (status: number, data: OrchestrationEvaluateOfferResponse) => {
    analytics.logEvent(Analytics.Events.SOFT_CREDIT_CHECK_ERROR_MODAL)
    const errorReason = data && !isValidResponseData(data) ? data.reason : 'unknown'
    logger.error('Evaluate Offer Failed', `status: ${status} - isRelink: ${false} - reason: ${errorReason}`)
    const errorModalContent = getErrorModalContent(status, data)
    modal.show({
      title: errorModalContent.title,
      cta: 'Confirm',
      render: () => errorModalContent.children,
    })
  }

  const redirectToOfferDetails = async () => {
    if (isFlagEnabledForSLC) {
      trackSLCExposure(isEnabledForSLC)
    }

    if (isEnabledForSLC) {
      return navigate(Routes.Onboarding.HOW_FLEX_WORKS, { replace: true })
    }

    return navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE, { replace: true })
  }

  const handleOfferEvaluationResponse = async (
    response: AxiosResponse<OrchestrationEvaluateOfferResponse> | undefined
  ) => {
    if (response) {
      stop()
      if (response.status === 201 && isValidResponseData(response.data)) {
        const offer = response.data.risk_offer
        const pricingoffer = response.data.pricing_offer

        if (!offer || !pricingoffer) {
          logger.error('Evaluate Offer Failed', `missing data, risk_offer: ${!offer}, pricing_offer: ${!pricingoffer}`)
          redirectToContactSupport()
          return
        }

        setOfferState({ initialized: true, offer })
        setPricingOfferState({ pricing_offer: pricingoffer })

        analytics.logEvent(Analytics.Events.ONBOARDING_WAITING_OFFER_FOUND)
        switch (offer.offer_state) {
          case OfferStates.PENDING_ACCEPT:
            iterable.addEvent(createUnderwritingApprovedEvent())
            redirectToOfferDetails()
            break
          case OfferStates.DENIED:
            const dataFields = { denial_reason: 'Denied' }
            iterable.addEvent(createUnderwritingDeniedEvent({ dataFields }))
            navigate(Routes.Onboarding.UPSELL_ELIGIBILITY, { replace: true })
            break
          default:
            logger.error('Evaluate Offer Failed', `offer_state is not PendingAccept on offer: ${offer.offer_id}`)
            redirectToContactSupport()
            break
        }
      } else if (response.status >= 400 && response.status < 500) {
        handleShowErrorMessage(response.status, response.data)
      } else {
        redirectToContactSupport()
        logger.error('Evaluate Offer Failed', `Status: ${response.status}`)
      }
    }
  }

  useEffect(() => {
    if (response && !loadingEvaluation) {
      handleOfferEvaluationResponse(response)
    }
  }, [response, stop, loadingEvaluation, isUserEligibleForAutopilot])

  const onSetSSN = (ssn: string) => {
    const maskedSSN = SSNMask.mask(ssn)
    clearErrors('ssn')
    setValue('ssn', maskedSSN)
  }

  const onSetDate = (dateOfBirth: string) => {
    const maskedDate = DateOfBirth.mask(dateOfBirth)
    clearErrors('dob')
    setValue('dob', maskedDate)
  }

  const onSetIncome = (income: string) => {
    clearErrors('income')
    setValue('income', income)
  }

  const getDoB = () => {
    const date = getValues('dob')
    const asDate = DateOfBirth.parseDate(date)
    const day = asDate.getDate() <= 9 ? `0${asDate.getDate()}` : `${asDate.getDate()}`

    const month = asDate.getMonth() + 1 <= 9 ? `0${asDate.getMonth() + 1}` : `${asDate.getMonth() + 1}`

    return `${asDate.getFullYear()}-${month}-${day}`
  }

  const checkIsDobValid = () => {
    const date = getValues('dob')
    if (date.length < DATE_LENGTH - 2 || date.length > DATE_LENGTH) {
      return 'Invalid date'
    }

    return isDOBValid(date) ? '' : 'You must be at least 18 years old'
  }

  const onClickContinue = handleSubmit(async (formValues: SoftCreditCheckState) => {
    const { ssn, income } = formValues
    analytics.logEvent(Analytics.Events.SOFT_CREDIT_CHECK_CTA_CLICKED)

    if (!uid || !billerConnection?.biller_account_public_id) {
      logger.error('evaluateOffer', `info: missing customerAccountState data`)
      return
    }

    const isSSNValid = isValidSSN(ssn)
    const dobError = checkIsDobValid()
    const incomeError = isIncomeCollectionEnabled && income.length > 8

    if (isValidITIN(ssn)) {
      setError('ssn', { type: 'Social security number', message: 'ITIN is not currently supported' })
    } else if (!isSSNValid) {
      setError('ssn', { type: 'Social security number', message: 'Social security number is invalid' })
    } else {
      clearErrors('ssn')
    }

    if (dobError) {
      setError('dob', { type: 'Invalid date', message: dobError })
    } else {
      clearErrors('dob')
    }

    if (incomeError) {
      setError('income', { type: 'Income', message: 'Income is too high' })
    } else {
      clearErrors('income')
    }

    if (!isSSNValid || dobError || incomeError) {
      return
    }
    start()
    setLoadingEvaluation(true)
    marTech.trackUserSubmittedCreditCheckInfo(customer, billerConnection, property, slcType, rentAmount)

    try {
      await evaluateOffer({
        customerId: uid,
        biller_account_public_id: billerConnection.biller_account_public_id,
        offer_id: offerState.offer.offer_id,
        date_of_birth: getDoB(),
        estimated_income_cent: isIncomeCollectionEnabled ? +income * 100 : undefined,
        ssn,
      })
      await refetchEligibility()
      // we check flag enablement in the hook
      await refetchSLC()
    } catch (error: any) {
      setLoadingEvaluation(false)
      logger.error('evaluateOffer - catch', `info: ${error?.message}`)
      redirectToContactSupport()
      stop()
    } finally {
      setLoadingEvaluation(false)
    }
  })

  const handleLearnMore = () => {
    analytics.logEvent(Analytics.Events.SOFT_CREDIT_CHECK_LEARN_MORE_CLICKED)

    modal.show({
      title: 'More about credit checks',
      cta: 'Done',
      render: () => {
        return (
          <>
            <SmallText>
              Flex needs to access your credit report in order to present an offer for a Flex credit line. Your credit
              score and other information will be used to determine how much credit you may be offered. Your Flex credit
              line will determine how you&rsquo;ll be able to split up your rent payments.
            </SmallText>
            <Box minH="24px" />
            <SmallText>
              Because this is a &ldquo;soft&rdquo; credit check, your credit score will not be impacted. A soft credit
              check is when your credit report is pulled but you haven&rsquo;t applied for credit.
            </SmallText>
          </>
        )
      },
    })
  }

  const handlePressWhy = () => {
    analytics.logEvent(Analytics.Events.SOFT_CREDIT_CHECK_WHY_COLLECT_CLICKED)

    modal.show({
      title: WHY_WE_COLLECT_PERSONAL_INFO_MODAL.TITLE,
      cta: 'Close',
      render: () => {
        return (
          <Box>
            <SmallText>{WHY_WE_COLLECT_PERSONAL_INFO_MODAL.MAIN_TEXT_1}</SmallText>
            <Box minH={'16px'} />
            <SmallText>{WHY_WE_COLLECT_PERSONAL_INFO_MODAL.MAIN_TEXT_2}</SmallText>
          </Box>
        )
      },
    })
  }

  return (
    <>
      {loadingEvaluation ? (
        <WaitingOffer />
      ) : (
        <SoftCreditCheck
          analyticsScreenName={Analytics.Screens.SOFT_CREDIT_CHECK}
          onClickContinue={onClickContinue}
          ssnPlaceholder={ssnPlaceholder}
          onDateChange={onSetDate}
          onSetSSN={onSetSSN}
          onSetIncome={onSetIncome}
          isLoading={loadingEvaluation}
          onLearnMore={handleLearnMore}
          onPressWhy={handlePressWhy}
          control={control}
          formErrors={formErrors}
          getValues={getValues}
        />
      )}
    </>
  )
}
