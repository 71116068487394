import React from 'react'
import { Analytics } from '@genoa/analytics'
import { LocalStorageProductType, LocalStorageProductVariant } from '@genoa/domain'

import { useAuthState } from './contexts'
import { useSetEmbedParams, useTriggerApplicationOpen } from './hooks'
import { useSetDeviceMetadata } from './hooks/use-set-device-metadata'
import { useAmplitude, useAnalytics } from './providers'
import { useAdClickIdentifiers } from './providers/analytics/use-ad-click-identifiers'
import { Routing } from './routing/Routing'
import { useFontStyles } from './views/components'

export const App = () => {
  useFontStyles()
  const { experimentLoaded } = useAmplitude()
  const analytics = useAnalytics()
  const triggerApplicationOpen = useTriggerApplicationOpen()
  const { uid, isAnonymous } = useAuthState()

  useAdClickIdentifiers()
  useSetDeviceMetadata()
  useSetEmbedParams()

  React.useEffect(() => {
    const currentUrl = window.location.href

    if (currentUrl.includes('product=credit-builder')) {
      localStorage.setItem('flexapp/product', LocalStorageProductType.CREDIT_BUILDER)
    }

    if (currentUrl.includes('product=rent-split')) {
      localStorage.setItem('flexapp/product', LocalStorageProductType.RENT_SPLIT)
    }
  }, [])

  React.useEffect(() => {
    // Waiting till we have a valid customer before logging analytics for product types
    if (uid && !isAnonymous) {
      const type = localStorage.getItem('flexapp/product')
      const variant =
        type === LocalStorageProductType.CREDIT_BUILDER
          ? LocalStorageProductVariant.CREDIT_BUILDER_TREATMENT
          : LocalStorageProductVariant.CREDIT_BUILDER_CONTROL

      if (type) {
        analytics.logEvent(Analytics.Events.PRODUCT_LINK_ASSIGNMENT, {
          type,
          variant,
        })
        analytics.setUserProperty(Analytics.UserProperties.PRODUCT_TYPE, type || LocalStorageProductType.RENT_SPLIT)
      }
    }
  }, [uid, isAnonymous])

  React.useEffect(() => {
    triggerApplicationOpen()
  }, [])

  return experimentLoaded ? <Routing /> : null
}
