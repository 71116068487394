import { useAccount } from '../../../../../../hooks/use-account'
import { FullScreenSpinnerLoading } from '../../../../../components'
import { SimplifiedEpisodic } from './SimplifiedEpisodic'
import { useBillPayNowSubmit } from './use-bill-pay-now-submit'
import { useCustomerWalletCard } from './use-customer-wallet-card'
import { useGetPayments } from './use-get-payments'

export const SimplifiedEpisodicContainer = () => {
  useAccount()

  const { amountCent, downPaymentAmount, isSimplifiedEpisodicReady, isStartPayLoading, payTo, secondPayment } =
    useGetPayments()
  const { cardLast4Digits, cardType } = useCustomerWalletCard()
  const { isBillPayNowSubmitLoading, handlePayButtonPress } = useBillPayNowSubmit({ downPaymentAmount })

  if (!isSimplifiedEpisodicReady) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <SimplifiedEpisodic
      cardLast4Digits={cardLast4Digits}
      cardType={cardType}
      disabled={isStartPayLoading || isBillPayNowSubmitLoading}
      downPaymentAmount={downPaymentAmount}
      handlePayButtonPress={handlePayButtonPress}
      loading={isStartPayLoading || isBillPayNowSubmitLoading}
      propertyName={payTo}
      secondPayment={secondPayment}
      totalAmount={amountCent}
    />
  )
}
