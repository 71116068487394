import { useCallback, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { OnboardingFlowName, SLCType } from '@genoa/domain'
import { OrchestrationInitiateOfferResponse, useInitiateOffer } from '@genoa/middle-end'

import { AuthenticationContextData } from '../../../contexts'
import { setOfferAction } from '../../../modules/flex2/offer'
import { setPricingOfferAction } from '../../../modules/flex2/pricing-offer'
import { useAnalytics, useIterable, useLogger } from '../../../providers'
import { useSecureLineOfCredit } from '../../secure-line-of-credit'
import { useAccount } from '../../use-account'
import { useReduxAction } from '../../use-redux-action'

export interface InitiateOfferOptions {
  /**
   * USD rent amount in _cents_
   */
  readonly amount: number
  readonly propertyName?: string
  readonly biller_account_public_id: string
  readonly uid: string
}

export interface UseInitiateOfferOptions {
  readonly componentName: string
  readonly handleSuccess: () => void
  readonly handleRentAmountError: (amount: number) => void
  readonly handleError: () => void
}

export const useHandleInitiateOffer = ({
  componentName,
  handleSuccess,
  handleRentAmountError,
  handleError,
}: UseInitiateOfferOptions) => {
  const analytics = useAnalytics()
  const iterable = useIterable()
  const loggerV2 = useLogger(`use-initiate-offer - ${componentName}`)
  const [loading, setLoading] = useState(false)
  const [, initiateOffer] = useInitiateOffer()
  const { billerConnection } = useAccount()
  const { slcType } = useSecureLineOfCredit()

  const setOfferState = useReduxAction(setOfferAction)
  const setPricingOfferState = useReduxAction(setPricingOfferAction)

  const handleBadRequestError = (amount: number) => {
    analytics.logEvent(Analytics.Events.INITIATE_OFFER_FAIL, { amount })
    loggerV2.error('initiateOffer', `Bad request - amount: ${amount}`)
    return handleRentAmountError(amount)
  }

  const handleUnknownError = (error: any) => {
    analytics.logEvent(Analytics.Events.INITIATE_OFFER_FAIL)
    loggerV2.error('initiateOffer', `info: ${error?.message}`)
    return handleError()
  }

  const handleInitiateOfferSuccess = (amount: number, data: OrchestrationInitiateOfferResponse) => {
    loggerV2.info('Initiate offer success', undefined, { amount })
    analytics.logEvent(Analytics.Events.INITIATE_OFFER_SUCC, { amount })
    iterable.setAppRentAmount(amount)

    const onboardingFlow =
      slcType === SLCType.CREDIT_BUILDER ? OnboardingFlowName.CREDIT_BUILDER : OnboardingFlowName.SPLIT_RENT
    analytics.setUserProperty(Analytics.UserProperties.ONBOARDING_FLOW_NAME, onboardingFlow)

    setOfferState({
      initialized: true,
      offer: data.risk_offer,
    })

    setPricingOfferState({
      pricing_offer: data.pricing_offer,
    })

    return handleSuccess()
  }

  const handleInitiateOfferInternal = async ({
    amount,
    propertyName,
    biller_account_public_id,
    uid,
  }: InitiateOfferOptions) => {
    analytics.logEvent(Analytics.Events.INITIATE_OFFER, {
      amount,
      biller_account_public_id,
    })
    loggerV2.info('Initiate offer attempt', undefined, { amount, biller_account_public_id })

    try {
      const response = await initiateOffer({
        customerId: uid,
        biller_account_public_id: biller_account_public_id,
        rental_property: {
          estimated_bill_amount_cent: amount,
          property_name: propertyName,
        },
      })

      if (response.status === 201) {
        return handleInitiateOfferSuccess(amount, response.data)
      }

      if (response.status === 400) {
        return handleBadRequestError(amount)
      }

      throw new Error(`Unexpected response, ${response.status} ${response.data}`)
    } catch (error: any) {
      return handleUnknownError(error)
    }
  }

  const handleInitiateOffer = useCallback(
    async (amount: number, userInfo: AuthenticationContextData, propertyName?: string) => {
      if (!loading && userInfo && userInfo.uid && billerConnection?.biller_account_public_id) {
        setLoading(true)
        await handleInitiateOfferInternal({
          amount: amount * 100,
          biller_account_public_id: billerConnection.biller_account_public_id,
          uid: userInfo.uid,
          propertyName,
        })
        setLoading(false)
      }
    },
    [loading, billerConnection?.biller_account_public_id]
  )

  return [loading, handleInitiateOffer] as const
}
