import { useEffect } from 'react'

enum ClickIdentifier {
  Google = 'gclid',
  Facebook = 'fbclid',
  TikTok = 'ttclid',
  LinkedIn = 'li_fat_id',
}

type ClickIdentifiersMap = Map<ClickIdentifier, string | undefined>

const adClickIds: ClickIdentifiersMap = new Map(
  Object.keys(ClickIdentifier).map((item) => [item as ClickIdentifier, undefined])
)

export const useAdClickIdentifiers = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    for (const [adNetwork, key] of Object.entries(ClickIdentifier)) {
      if (params.has(key)) {
        const queryParamValue = params.get(key)
        if (queryParamValue !== null) {
          adClickIds.set(adNetwork as ClickIdentifier, queryParamValue)
        }
      }
    }
  }, [])
}

export const getAdClickIds = () =>
  [...adClickIds.entries()].reduce(
    (previous, [id, value]) =>
      value
        ? {
            ...previous,
            [ClickIdentifier[id as unknown as keyof typeof ClickIdentifier]]: value,
          }
        : previous,
    {}
  )
