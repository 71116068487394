import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import {
  FlexLinks,
  ONBOARDING_AGREEMENT_TYPES,
  ONBOARDING_SKIP_AUTOPILOT_AGREEMENT_TYPES,
  SLC_ONBOARDING_AGREEMENT_TYPES,
  SLC_PRICING_ONBOARDING_AGREEMENT_TYPES,
} from '@genoa/domain'
import { useAcceptOffer, useAssignCustomerDda, useGetAgreements } from '@genoa/middle-end'
import { ErrorResponseData } from '@genoa/middle-end/lib/types'
import { Box, Flex } from '@chakra-ui/react'

import { useAuthState, useModal } from '../../../../contexts'
import { useAutopilotEligibility, useFlexAnywhereUser, useIsEmbed, useReduxAction } from '../../../../hooks'
import { useGetOffer } from '../../../../hooks/flex2/risk'
import { useSecureLineOfCredit } from '../../../../hooks/secure-line-of-credit'
import { useAccount } from '../../../../hooks/use-account'
import { setOfferAction } from '../../../../modules/flex2/offer'
import { usePricingOfferState } from '../../../../modules/flex2/use-state'
import {
  OnboardingStatus,
  setOnboardingStatus,
  useAnalytics,
  useFees,
  useFlexLinks,
  useIterable,
  useLogger,
} from '../../../../providers'
import { createSignUpCompletedEvent } from '../../../../providers/iterable/user-events'
import * as Routes from '../../../../routing/constants'
import { App } from '../../../../routing/constants'
import { InlineButton, SmallText } from '../../../components'
import { FullScreenSpinnerLoading } from '../../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { ConfirmDateModalContainer } from '../payment-date-modal/V2ConfirmDateModalContainer'
import { useOffer } from '../use-offer'
import { AcceptMembershipPolicies } from './accept-membership-policies/AcceptMembershipPolicies'
import { AcceptanceOfferDetails } from './AcceptanceOfferDetails'

type V2AcceptanceOfferDetailsContainer = {}

const resolveAgreementTypes = (isEnabledForSLC: boolean, isUserAutopayEnabled: boolean, isPricingEnabled: boolean) => {
  if (isPricingEnabled) {
    return SLC_PRICING_ONBOARDING_AGREEMENT_TYPES
  }
  if (isEnabledForSLC) {
    return SLC_ONBOARDING_AGREEMENT_TYPES
  }
  if (isUserAutopayEnabled) {
    return ONBOARDING_AGREEMENT_TYPES
  }
  return ONBOARDING_SKIP_AUTOPILOT_AGREEMENT_TYPES
}

export const V2AcceptanceOfferDetailsContainer = (props: V2AcceptanceOfferDetailsContainer) => {
  const logger = useLogger('V2AcceptanceOfferDetailsContainer')
  const [loadingSetup, setLoadingSetup] = useState(true)
  const analytics = useAnalytics()
  const modal = useModal()
  const navigate = useNavigate()
  const ctaTitle = 'Accept'
  const flexLinks = useFlexLinks()
  const offer = useOffer()
  const setOfferState = useReduxAction(setOfferAction)
  const pricingOffer = usePricingOfferState()
  const { user, uid } = useAuthState()
  const {
    processingFeePercentage,
    isCardFeePassthroughEnabled,
    installments: payments,
    offerMonthlyRent: rentAmount,
    subscriptionFee: offerFee,
  } = useFees()
  const iterable = useIterable()
  const isOutOfNetworkUser = useFlexAnywhereUser()
  const { isUserAutopayEnabled, refetchEligibility } = useAutopilotEligibility()
  const [{ response: agreementsResponse }, getAgreements] = useGetAgreements()
  const { isEnabledForSLC, isLoadingSLC, isPricingEnabled } = useSecureLineOfCredit(true)
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const { billerConnection } = useAccount()
  const [{ loading: acceptLoading }, acceptOffer] = useAcceptOffer()
  const [{ loading: assignCustomerDdaLoading }, assignCustomerDda] = useAssignCustomerDda()
  const { getOffer } = useGetOffer()
  const isEmbed = useIsEmbed()

  useEffect(() => {
    const handleSetup = async () => {
      try {
        const autopilotEligibility = await refetchEligibility()
        const agreementTypes = resolveAgreementTypes(
          isEnabledForSLC,
          autopilotEligibility.isUserAutopayEnabled!,
          isPricingEnabled
        )

        analytics.logScreenView(Analytics.Screens.ACCEPTANCE_OFFER_DETAILS, {
          autopay_enabled: autopilotEligibility.isUserAutopayEnabled,
        })

        await getAgreements({
          customerId: uid!,
          agreementTypes,
          onlyFinancialPartnerSpecific: true,
        })
      } catch (error: any) {
        logger.error('Error in setup', error?.message)
        navigate(App.TRY_AGAIN)
      } finally {
        setLoadingSetup(false)
      }
    }
    if (!isLoadingSLC) {
      handleSetup()
    }
  }, [uid, isEnabledForSLC, isLoadingSLC, isPricingEnabled])

  useEffect(() => {
    const handleGetOffer = async () => {
      if (billerConnection?.biller_account_public_id) {
        await getOffer(billerConnection.biller_account_public_id)
      }
    }
    handleGetOffer()
  }, [billerConnection?.biller_account_public_id])

  useEffect(() => {
    if (agreementsResponse) {
      const agreementsData = agreementsResponse.data
      if (agreementsResponse?.status !== 200 || agreementsData?.error) {
        const logArgs = {
          code: agreementsData?.error?.code,
          message: agreementsData?.error?.message,
          status: agreementsResponse?.status,
        }
        logger.error('Error fetching agreements', undefined, logArgs)
        navigate(App.TRY_AGAIN)
      }
    }
  }, [agreementsResponse])

  const initialDaySelected = useMemo(() => {
    if (payments.length) {
      return payments[payments.length - 1].day
    }

    return -1
  }, [payments])

  useEffect(() => {
    analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_CTA_DISPLAYED)
  }, [])

  const renderGenericErrorAcceptOffer = () => {
    analytics.logEvent(Analytics.Events.ACCEPT_OFFER_FAIL)
    modal.show({
      title: 'Something went wrong',
      cta: 'Try again',
      render: () => <SmallText>We couldn&apos;t process your request.</SmallText>,
    })
  }
  const renderDataErrorAcceptOffer = (content: string) => {
    modal.show({
      title: 'Something went wrong',
      cta: 'Close',
      render: () => <SmallText>{content}</SmallText>,
    })
  }

  const renderRateLimitExceededAcceptOffer = () => {
    modal.show({
      title: 'Too many attempts',
      cta: 'Close',
      render: () => (
        <SmallText>
          You&apos;ve tried too many times, and we&apos;re running into some issues processing your request. Please try
          again later.
        </SmallText>
      ),
    })
  }

  const handleAcceptOffer = async () => {
    if (!user?.uid) return

    const requestData = {
      offerId: offer.offer.offer_id,
      repayment_day: isEnabledForSLC ? undefined : offer.repayment_day,
      customerPublicId: user.uid,
    }

    analytics.logEvent(Analytics.Events.ACCEPT_OFFER_REQUEST)
    const acceptOfferResponse = await acceptOffer(requestData)

    if (acceptOfferResponse.status >= 200 && acceptOfferResponse.status < 300) {
      setOfferState({ initialized: true, offer: acceptOfferResponse.data })
      await assignDda()
      handleSuccess()
    } else {
      analytics.logEvent(Analytics.Events.ACCEPT_OFFER_FAIL)

      const { status, data } = acceptOfferResponse

      if (status === 429) {
        logger.error('AcceptOffer', 'Rate limit exceeded')
        renderRateLimitExceededAcceptOffer()
      } else if (status === 422) {
        const errorResponse = data as unknown as ErrorResponseData
        logger.error('AcceptOffer', errorResponse?.reason)
        renderDataErrorAcceptOffer(errorResponse?.message)
      } else {
        logger.error('AcceptOffer', `status: ${status}`)
        renderGenericErrorAcceptOffer()
      }
    }
  }

  const handleLearnMore = useCallback(() => {
    analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_LEARN_MORE_CLICKED)

    modal.show({
      title: '2nd payment to Flex',
      cta: 'Close',
      render: () => {
        return (
          <>
            <SmallText>You can change the date of this payment in Settings after signup is complete</SmallText>
          </>
        )
      },
    })
  }, [])

  const handleMembershipLearnMore = useCallback(() => {
    modal.show({
      title: 'Membership fee',
      cta: 'Close',
      render: () => {
        return (
          <Flex flexFlow={'column'}>
            <SmallText>Your membership will automatically renew each month until you cancel.</SmallText>
            <SmallText>
              You may cancel by contacting us at <a href="mailto:help@getflex.com"></a>help@getflex.com.
            </SmallText>
            <Box minH="24px" />
            <Flex justifyContent={'center'}>
              <InlineButton
                onClick={() => {
                  flexLinks.open(FlexLinks.membershipFeeLearnMore)
                }}
              >
                <SmallText>
                  <b>Learn more about membership</b>
                </SmallText>
              </InlineButton>
            </Flex>
          </Flex>
        )
      },
    })
  }, [])

  const handleChooseDate = useCallback(() => {
    analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_CHOOSE_DATE_CLICKED)
    setDateModalOpen(true)
  }, [])

  const handleNext = async () => {
    analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_CTA_CLICKED)
    if (offer.initialized && offer.offer && billerConnection?.biller_account_public_id) {
      try {
        await handleAcceptOffer()
      } catch (error: any) {
        logger.error('AcceptOffer', `unhandled error info: ${error?.message}`)
        renderGenericErrorAcceptOffer()
      }
    }
  }

  const toggleAcceptTerms = useCallback(() => {
    const newValue = !termsAccepted
    setTermsAccepted(newValue)
    if (newValue) {
      analytics.logEvent(Analytics.Events.ACCEPTANCE_OFFER_DETAILS_ACCEPT_TERMS_LINK_CLICKED)
    }
  }, [termsAccepted])

  const handleSuccess = useCallback(() => {
    analytics.logEvent(Analytics.Events.ACCEPT_OFFER_SUCCESS)
    iterable.addEvent(createSignUpCompletedEvent())
    setOnboardingStatus(OnboardingStatus.Active)
    analytics.setUserProperty(
      Analytics.UserProperties.ONBOARDING_COMPLETION_TIMESTAMP,
      new Date().toISOString().slice(0, 19),
      true
    )

    if (isOutOfNetworkUser) {
      navigate(Routes.Onboarding.FLEX_ANYWHERE_HOW_FLEX_WORKS)
    } else if (isEmbed && !isUserAutopayEnabled) {
      navigate(Routes.Embed.CONGRATS)
    } else {
      navigate(Routes.Onboarding.CONGRATS)
    }
  }, [history, isEmbed, isUserAutopayEnabled, setOnboardingStatus])

  const assignDda = async () => {
    if (billerConnection?.biller.system === 'portal' && user) {
      try {
        const assignCustomerDdaResponse = await assignCustomerDda({ customerId: user.uid })
        if (assignCustomerDdaResponse.status !== 201) {
          logger.error(
            'assignCustomerDda',
            `info: ${JSON.stringify({
              status: assignCustomerDdaResponse.status,
              data: assignCustomerDdaResponse.data,
            })}`
          )
        }
      } catch (error: any) {
        logger.error('assignCustomerDda - catch', `info: ${error?.message}}`)
      }
    }
  }

  const handleCloseDateModal = useCallback(() => {
    analytics.logEvent(Analytics.Events.CHOOSE_DATE_BACK_CLICKED)
    setDateModalOpen(false)
  }, [])

  if (loadingSetup || isLoadingSLC) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <>
      {isUserAutopayEnabled || isEmbed ? (
        <AcceptanceOfferDetails
          onAcceptTerms={toggleAcceptTerms}
          onLearnMore={handleLearnMore}
          onMembershipLearnMore={handleMembershipLearnMore}
          onChooseDate={handleChooseDate}
          onNext={handleNext}
          loading={acceptLoading || assignCustomerDdaLoading}
          termsAccepted={termsAccepted}
          rentAmount={rentAmount}
          payments={payments}
          offerFee={offerFee}
          ctaTitle={ctaTitle}
          showExtraFees={isCardFeePassthroughEnabled}
          processingFeePercentage={processingFeePercentage}
          membershipFeeReason={pricingOffer.pricing_offer.membership_fee_reason}
          agreements={agreementsResponse?.data.data?.agreements?.pending || []}
        />
      ) : (
        <AcceptMembershipPolicies
          handleNavigation={handleNext}
          toggleAcceptTerms={toggleAcceptTerms}
          loading={acceptLoading || assignCustomerDdaLoading}
          termsAccepted={termsAccepted}
          offerFee={offerFee}
          agreements={agreementsResponse?.data.data?.agreements?.pending || []}
        />
      )}

      <ConfirmDateModalContainer
        initialDaySelected={initialDaySelected}
        onClose={handleCloseDateModal}
        isOpen={dateModalOpen}
      />
    </>
  )
}
