import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { usePhoneVerification } from '@genoa/middle-end'
import { Box } from '@chakra-ui/react'
import { ConfirmationResult, getAuth, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'

import { useAuthState } from '../../../contexts'
import { useIsEmbed, useReduxAction } from '../../../hooks'
import { useEmbedPrefills } from '../../../hooks/prefills/use-embed-prefills'
import { PhoneNumberState, RootState, setPhoneNumberAction } from '../../../modules'
import {
  Config,
  logger,
  setUserSignupStatus,
  useAmplitude,
  useAnalytics,
  useFirebase,
  useIterable,
} from '../../../providers'
import { createPhoneVerifiedEvent } from '../../../providers/iterable/user-events'
import * as Routes from '../../../routing/constants'
import { Keyboard } from '../../../tools'
import { ConfirmPIN } from './ConfirmPIN'
import { NewCodeSent } from './NewCodeSent'
import { PinSendingProblem } from './PinSendingProblem'
import { SuccessfulPin } from './SuccessfulPin'
import { UserDisabled } from './UserDisabled'
import { WrongPin } from './WrongPin'

type VisibleModal = 'none' | 'wrong-pin' | 'pin-sending-problem' | 'successful-pin' | 'new-pin-sent' | 'user-disabled'

const pinErrorMessages: Record<string, string> = {
  'auth/too-many-requests':
    'You’ve exceeded the maximum number of attempts. Please wait at least an hour and try again.',
  'auth/invalid-phone-number':
    'We couldn’t recognize the number you entered. Please try again with a valid phone number.',
  'auth/no-confirm':
    'We could not verify your mobile number. Please make sure you entered a valid phone number and try again.',
  'auth/network-request-failed':
    'There was an issue with your network connection. Please make sure you are connected to the internet and try again.',
  'auth/generic-pin-error': 'We ran into issues verifying your phone number. Please try again.',
  'auth/code-expired': 'It looks like your verification code timed out. Please resend the code and try again.',
  'auth/session-expired': 'Your session has expired. Please go back and re-enter your phone number and try again.',
}

const OTP_TIMESTAMP_LOCAL_STORAGE_KEY = 'flexapp/last_requested_otp'
export const OTP_COOLDOWN_IN_MS = 30000
export const MAX_PIN_DIGITS = 6

export interface ConfirmPinContainerProps {
  readonly onNext: () => Promise<void>
}

export const ConfirmPinContainer = (props: ConfirmPinContainerProps) => {
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const phoneNumber: PhoneNumberState = useSelector((state: RootState) => state.onboarding.phoneNumber)
  const setPhoneNumber = useReduxAction(setPhoneNumberAction)
  const [allowResend, setAllowResend] = useState(false)
  const [currentUserId, setCurrentUserId] = useState('')
  const [visibleModal, setVisibleModal] = useState<VisibleModal>('none')
  const [pinErrorMessage, setpPinErrorMessage] = useState('')
  const [confirm, setConfirm] = useState<ConfirmationResult | null>(null)
  const [loading, setLoading] = useState(false)
  const [, phoneVerification] = usePhoneVerification()
  const { token, isAnonymous, user, uid, setProcessingLogin } = useAuthState()
  const [isPinInputDisabled, setIsPinInputDisabled] = useState(!Config.E2E)
  const [isOTPCodeSent, setIsOTPCodeSent] = useState(false)
  const [pin, setPin] = useState('')
  const recaptchaRef = useRef<HTMLDivElement>(null)
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null)
  const [hasSignedIn, setHasSignedIn] = useState(false)
  const iterable = useIterable()
  const { refetchExperimentsForUser } = useAmplitude()
  const [smsNotificationsAccepted, setSMSNotificationsAccepted] = useState(false)
  const firebaseApp = useFirebase()
  const auth = getAuth(firebaseApp)
  const { handlePrefill } = useEmbedPrefills()
  const isEmbed = useIsEmbed()

  useEffect(() => {
    setProcessingLogin(true)
  }, [])

  useEffect(() => {
    const map: { [key: string]: Analytics.Screens } = {
      'wrong-pin': Analytics.Screens.SIGNIN_WRONG_PIN_MODAL,
      'pin-sending-problem': Analytics.Screens.SIGNIN_PIN_SENDING_PROBLEM_MODAL,
      'user-disabled': Analytics.Screens.SIGNIN_PIN_SENDING_PROBLEM_MODAL,
      'successful-pin': Analytics.Screens.SIGNIN_SUCCESSFUL_PIN_MODAL,
      'new-pin-sent': Analytics.Screens.SIGNIN_NEW_PIN_SENT_MODAL,
    }

    // Analytics.Screens.SIGNIN_CONFIRM_PIN screen gets called in basePageLayout
    if (visibleModal !== 'none') {
      const screen = map[visibleModal]
      analytics.logScreenView(screen)
    }
  }, [visibleModal])

  const handlePinAuthError = useCallback(
    (code?: string) => {
      // use a custom modal for this one so we can hyperlink
      if (code === 'auth/user-disabled') {
        analytics.logEvent(Analytics.Events.SIGNIN_PIN_ERROR_MODAL_VIEWED, { error: 'auth/user-disabled' })
        return setVisibleModal('user-disabled')
      }

      // handle undefined or generic errors
      if (code === undefined || pinErrorMessages[code] === undefined) {
        const message = pinErrorMessages['auth/generic-pin-error']
        setpPinErrorMessage(message)
        analytics.logEvent(Analytics.Events.SIGNIN_PIN_ERROR_MODAL_VIEWED, { error: code ?? 'auth/generic-pin-error' })
        return setVisibleModal('pin-sending-problem')
      }

      const message = pinErrorMessages[code]
      setpPinErrorMessage(message)
      analytics.logEvent(Analytics.Events.SIGNIN_PIN_ERROR_MODAL_VIEWED, { error: code })
      return setVisibleModal('pin-sending-problem')
    },
    [analytics]
  )

  const requestCodeTo = useCallback(
    async (phoneNumberToSend: string) => {
      const otpTimestamp = Number(localStorage.getItem(OTP_TIMESTAMP_LOCAL_STORAGE_KEY))

      if (otpTimestamp + OTP_COOLDOWN_IN_MS > Date.now()) {
        return
      }

      localStorage.setItem(OTP_TIMESTAMP_LOCAL_STORAGE_KEY, `${Date.now()}`)

      setAllowResend(false)
      setTimeout(() => {
        setAllowResend(true)
      }, OTP_COOLDOWN_IN_MS)

      let localRecaptcha = recaptcha
      if (!localRecaptcha && recaptchaRef.current) {
        localRecaptcha = new RecaptchaVerifier(auth, recaptchaRef.current, {
          size: 'invisible',
          callback: (response: any) => {
            logger.log('Invisible Captcha', response)
            setIsPinInputDisabled(false)
          },
        })
        setRecaptcha(localRecaptcha)
      }

      const firebaseNumber = `+1${phoneNumberToSend}`

      try {
        logger.log('ConfirmPinContainer', `Requesting pin code to phone`)
        if (localRecaptcha) {
          const confirmation = await signInWithPhoneNumber(auth, firebaseNumber, localRecaptcha)
          logger.log('ConfirmPinContainer', `SMS sent to phone`)
          setIsOTPCodeSent(true)

          setConfirm(confirmation)
        }
      } catch (error: any) {
        setIsOTPCodeSent(false)

        logger.error(
          'ConfirmPinContainer',
          `error sending verification to phone: ${firebaseNumber} - ${error?.message}`
        )
        return handlePinAuthError(error.code)
      }
    },
    [phoneNumber.formatted, recaptcha]
  )

  const handleFetchExperiments = (userId: string) => {
    try {
      return refetchExperimentsForUser(userId)
    } catch (error: any) {
      logger.warn('ConfirmPINContainer', `Error loading experiments during sign in: ${error?.message}`)
    }
  }

  const handleSubmitPin = useCallback(
    async (pin: string) => {
      Keyboard.hide()

      if (!confirm) {
        return handlePinAuthError('auth/no-confirm')
      } else if (!loading) {
        setLoading(true)
        logger.log('ConfirmPINContainer', `User entered PIN`)
        const credential = PhoneAuthProvider.credential(confirm.verificationId, pin)
        logger.log('ConfirmPINContainer', `Phone Auth Credentials created`)
        try {
          const credentials = await confirm?.confirm(pin)
          if (credentials?.user?.uid) {
            await handleFetchExperiments(credentials.user.uid)
            await handleRegisterSigninPinCodeNext(credentials.user.uid)
            setCurrentUserId(credentials?.user?.uid)
          }
          iterable.addEvent(createPhoneVerifiedEvent())
          analytics.logEvent(Analytics.Events.PHONE_VERIFIED, {
            customerId: credentials?.user?.uid,
            embedFlow: isEmbed ? 'yardi' : 'none',
          })
          setUserSignupStatus()
          setVisibleModal('successful-pin')
        } catch (error: any) {
          logger.error('ConfirmPINContainer', `Error sign in: ${error?.message}`)
          if (error.code && error.code === 'auth/invalid-verification-code') {
            setLoading(false)
            analytics.logScreenView(Analytics.Screens.SIGNIN_WRONG_PIN_MODAL)
            return setVisibleModal('wrong-pin')
          }

          setLoading(false)
          return handlePinAuthError(error.code)
        }
        setLoading(false)
      }
    },
    [analytics, confirm, loading, user, smsNotificationsAccepted]
  )

  const handleSMSOptIn = useCallback(
    () => setSMSNotificationsAccepted(!smsNotificationsAccepted),
    [smsNotificationsAccepted]
  )

  useEffect(() => {
    if (pin.length === MAX_PIN_DIGITS) {
      handleSubmitPin(pin)
    }
  }, [pin])

  const onPINChange = useCallback(
    (digit: string) => {
      if (pin.length > MAX_PIN_DIGITS) {
        return
      }

      setPin(digit)
    },
    [pin]
  )

  const handleResendPinCode = () => {
    analytics.logEvent(Analytics.Events.SIGNIN_CONFIRM_PIN_RESEND_CODE)
    requestCodeTo(phoneNumber.extracted)
    setVisibleModal('new-pin-sent')
  }

  const handleWrongPinCodeBack = () => {
    analytics.logEvent(Analytics.Events.SIGNIN_WRONG_PIN_BACK_CLICKED, { phoneNumber })
    setVisibleModal('none')
    setPin('')
  }

  const handleRegisterSigninPinCodeNext = useCallback(
    async (customerId: string) => {
      if (!user?.uid) {
        throw Error('Missing user uid')
      }
      if (!hasSignedIn) {
        try {
          await phoneVerification({ customer_public_id: customerId, phone_number: `1${phoneNumber.extracted}` })
          setHasSignedIn(true)
        } catch (error: any) {
          logger.error('ConfirmPinContainer - handleRegisterSigninPinCodeNext', `${error?.message}`)
        }
      } else {
        return Promise.resolve()
      }
    },
    [token, hasSignedIn, user?.uid, phoneNumber.extracted]
  )

  const handleSMSOptInNext = useCallback(() => {
    analytics.logEvent(Analytics.Events.SIGNUP_SMS_OPT_IN_CLICKED, { optedIn: smsNotificationsAccepted })
    if (smsNotificationsAccepted) {
      iterable.setSMSOptInStatus(smsNotificationsAccepted)
    }
  }, [smsNotificationsAccepted])

  const handleSuccessfulPinCodeNext = async () => {
    analytics.logEvent(Analytics.Events.SIGNIN_SUCCESSFUL_PIN_NEXT_CLICKED, { phoneNumber })
    handleSMSOptInNext()
    if (token && !isAnonymous) {
      if (isEmbed) {
        // TODO: we could create similar navigation hooks like we use for standard prefills, see `use-navigate-to-property-selection`
        await handlePrefill({
          navigateToAddressSelection: () => navigate(Routes.Onboarding.ADDRESS),
        })
      }
      handleRedirectOnboarding()
      setVisibleModal('none')
    }
  }

  const handleRedirectOnboarding = () => {
    setProcessingLogin(false)
    navigate(Routes.App.ONBOARDING, { replace: true })
  }

  const handleNewCodeSentNext = () => {
    analytics.logEvent(Analytics.Events.SIGNIN_NEW_CODE_SENT_OKAY_CLICKED, { phoneNumber })
    setVisibleModal('none')
  }
  const handlePinSendingProblemBack = () => {
    analytics.logEvent(Analytics.Events.SIGNIN_WRONG_PIN_BACK_CLICKED, { phoneNumber })
    setVisibleModal('none')
    setPhoneNumber({ extracted: '', formatted: '' })
    navigate(Routes.Auth.PHONE, { replace: true })
  }

  useEffect(() => {
    logger.log('ConfirmPINContainer', `phoneNumber: ${phoneNumber.extracted}`)
    if (phoneNumber.extracted.length === 10 && !isOTPCodeSent) {
      requestCodeTo(phoneNumber.extracted)
    }
  }, [isOTPCodeSent, phoneNumber.extracted, requestCodeTo])

  return (
    <>
      <Box>
        <ConfirmPIN
          phoneNumber={phoneNumber.formatted}
          onPINChange={onPINChange}
          onResendCodeClick={handleResendPinCode}
          allowResend={allowResend}
          isDisabled={isPinInputDisabled}
          pin={pin}
        />
        <Box id="recaptcha-container" ref={recaptchaRef}></Box>
      </Box>

      <WrongPin visible={visibleModal === 'wrong-pin'} onClose={handleWrongPinCodeBack} />

      <SuccessfulPin
        visible={visibleModal === 'successful-pin'}
        onClose={handleSuccessfulPinCodeNext}
        smsNotificationsAccepted={smsNotificationsAccepted}
        onSMSOptIn={handleSMSOptIn}
      />

      <NewCodeSent
        formattedPhone={phoneNumber.formatted}
        visible={visibleModal === 'new-pin-sent'}
        onClose={handleNewCodeSentNext}
      />

      <PinSendingProblem
        visible={visibleModal === 'pin-sending-problem'}
        message={pinErrorMessage}
        onClose={handlePinSendingProblemBack}
      />

      <UserDisabled visible={visibleModal === 'user-disabled'} onClose={handlePinSendingProblemBack} />
    </>
  )
}
